.modal-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}

.modal {
  max-height: 75%;
  width: 20rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 1.75rem;
  position: relative;
  display: flex; /* Add flexbox layout */
  flex-direction: column; /* Set direction to column */
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.125rem;
}

.modal-content {
  flex-grow: 1; /* Allow content to grow and fill available height */
  overflow-y: auto; /* Add scroll if content overflows */
}
