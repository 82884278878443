body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* PLACEHOLDER COLOR */
::placeholder {
  color: #A0A0A0;
}

/* FONT GILROY */
@font-face {
  font-family: 'Gilroy-BoldItalic';
  src: local('Gilroy-BoldItalic'), url(./public/fonts/gilroy/Gilroy-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy-SemiBold'), url(./public/fonts/gilroy/Gilroy-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: local('Gilroy-ExtraBold'), url(./public/fonts/gilroy/Gilroy-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(./public/fonts/gilroy/Gilroy-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(./public/fonts/gilroy/Gilroy-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./public/fonts/gilroy/Gilroy-Light.ttf) format('truetype');
}

/* ANTD STEPS */
.ant-steps-item-icon {
  background-color: #1ECECA !important;
  border-color: #1ECECA !important;
}
.next-step .ant-steps-item-icon {
  background-color: #D6E6F0 !important;
  border-color: #D6E6F0 !important;
}
.next-step .ant-steps-item-title {
  color: #9FAFBB !important;
}
:where(.css-dev-only-do-not-override-1c0na6j).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #1ECECA !important;
}
.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: #1ECECA !important;
}

/* ANTD BUTTON */
.ant-upload {
  width: 100% !important;
}
.ant-upload .ant-btn {
  width: 100% !important;
}

/* NEWS LINES LIMITER */
.two-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.three-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.four-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

/* SPIN LOADER STYLE */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.bouncing-dots {
  display: flex;
  justify-content: space-between;
  width: 60px;
}
.dot {
  width: 15px;
  height: 15px;
  background-color: #4EF3EF;
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}
.dot:nth-child(1) {
  animation-delay: 0s;
}
.dot:nth-child(2) {
  animation-delay: 0.2s;
}
.dot:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes bounce {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-20px);
  }
}
